import React from 'react';
import Navbar from '../../../components/common/Navbar/Navbar';

const ConstellationsFamiliales = () => {
  return (
    <>
      <Navbar/>
      Ici page des constellations
    </>
  );
};

export default ConstellationsFamiliales;