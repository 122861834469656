import React from 'react';
import Navbar from '../../../components/common/Navbar/Navbar';

const MonParcours = () => {
  return (
    <>
      <Navbar/>
      
    </>
  );
};

export default MonParcours;